/* You can add global styles to this file, and also import other style files */

@import "~@ng-select/ng-select/themes/default.theme.css";

/**** Select option ***/


/* @import "~@ng-select/ng-select/themes/ant.design.theme.css"; */


/* @import '~@angular/material/prebuilt-themes/indigo-pink.css'; */

@import "~@ng-select/ng-select/themes/material.theme.css";

/* @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css"); */